import request from '@/utils/request/index'

export const ApiRequest = (path = '/', params = {}, loading = true, catchError = false, method = 'get', contentType = '', responseType = '') => {
  return new Promise((resolve, reject) => {
    const config = {}
    config.loading = loading
    if (contentType) {
      config.headers = { 'Content-Type': contentType }
    }
    if (method === 'get') {
      request.get(path, { params, ...config, responseType }).then((data) => {
        return resolve(data)
      }).catch(e => {
        if (catchError) {
          return reject(e)
        } else {
          return new Promise(() => {
          }) // 返回一个pending中的promise，请求不会进入catch中
        }
      })
    } else if (method === 'put') {
      request.put(path, params, config).then((data) => {
        return resolve(data)
      }).catch(e => {
        if (catchError) {
          return reject(e)
        } else {
          return new Promise(() => {
          }) // 返回一个pending中的promise，请求不会进入catch中
        }
      })
    } else if (method === 'post') {
      request.post(path, params, config).then((data) => {
        return resolve(data)
      }).catch(e => {
        if (catchError) {
          return reject(e)
        } else {
          return new Promise(() => {
          }) // 返回一个pending中的promise，请求不会进入catch中
        }
      })
    } else {
      request.delete(path, { params, ...config }).then((data) => {
        return resolve(data)
      }).catch(e => {
        if (catchError) {
          return reject(e)
        } else {
          return new Promise(() => {
          }) // 返回一个pending中的promise，请求不会进入catch中
        }
      })
    }
  })
}
