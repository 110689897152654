import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementIcons from '@element-plus/icons-vue'
import print from 'vue3-print-nb'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor (callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
const app = createApp(App)
app.use(router)
app.use(print)

app.use(ElementPlus, {
  locale: zhCn
})
for (const iconName in ElementIcons) {
  if (Reflect.has(ElementIcons, iconName)) {
    const ic = ElementIcons
    const item = ic[iconName]
    app.component(`ElIcon${iconName}`, item)
  }
}
app.mount('#app')
