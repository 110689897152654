import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/token'

let loadingInstance = null
let loadingNums = 0

function joinLoading () {
  if (loadingNums === 0) {
    loadingInstance = ElLoading.service({ lock: true, text: 'Loading', background: 'rgba(255,255,255,0.1)' })
  }
  loadingNums++
}

function removeLoading () {
  loadingNums = loadingNums <= 1 ? 0 : loadingNums - 1
  if (loadingNums === 0) {
    loadingInstance.close()
  }
}

const request = axios.create({
  baseURL: process.env.VUE_APP_API_ADDRESS ?? '/',
  timeout: 10000
})

request.interceptors.request.use(function (config) {
  const { loading } = config
  if (loading) {
    joinLoading()
  }
  const token = getToken()
  if (token && config.headers) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

request.interceptors.response.use(function (response) {
  const { loading } = response.config

  if (loading) {
    removeLoading()
  }
  const { code, message, data } = response.data
  if (response.headers.getContentType() === 'application/force-download') {
    const fileName = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate()) + '-' + (new Date().getHours()) + '-' + (new Date().getMinutes()) + '-' + (new Date().getSeconds())
    const data = response.data
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName + '.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return
  }

  switch (code) {
    case 200:
      return data
    case 401:
      removeToken()
      window.location.reload()
      return Promise.reject(response.data)
    case 400:
      showTips(message)
      return Promise.reject(response.data)
    default:
      showError(message)
      return Promise.reject(response.data)
  }
}, function (error) {
  if (error && error.config && error.config.loading) {
    removeLoading()
  }
  const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error
  const code = error.response && error.response.status ? error.response.status : 500
  switch (code) {
    case 401:
      removeToken()
      window.location.reload()
      break
    default:
      showError(message)
  }
  return Promise.reject(error.response.data)
})

function showError (error) {
  ElMessage({
    message: error,
    type: 'error'
  })
}

function showTips (error) {
  ElMessage({
    message: error,
    type: 'warning'
  })
}

export default request
