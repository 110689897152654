import { ApiRequest } from '@/utils/request/api'

/**
 * 异步接口示例
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const loginApi = (params = {}) => {
  return ApiRequest('/login', params, true, true, 'put')
}
export const routeApi = (params = {}) => {
  return ApiRequest('/route', params, true, false, 'get')
}
export const getUserListApi = (params = {}) => {
  return ApiRequest('/user/page-list', params, true, false, 'get')
}
export const setUserDisableApi = (params = {}) => {
  return ApiRequest('/user/set-disable', params, true, false, 'put')
}
export const resetUserPasswordApi = (params = {}) => {
  return ApiRequest('/user/reset-password', params, true, false, 'put')
}
export const deleteUserApi = (params = {}) => {
  return ApiRequest('/user/delete', params, true, false, 'delete')
}
export const createUserApi = (params = {}) => {
  return ApiRequest('/user/create', params, true, false, 'post')
}
export const updateUserApi = (params = {}) => {
  return ApiRequest('/user/update', params, true, false, 'post')
}
export const getRoleListApi = (params = {}) => {
  return ApiRequest('/role/list', params, true, false, 'get')
}
export const getAccountInfoApi = (params = {}) => {
  return ApiRequest('/account/info', params, true, false, 'get')
}
export const updateAccountPasswordApi = (params = {}) => {
  return ApiRequest('/account/update-password', params, true, false, 'get')
}
export const getHospitalListApi = (params = {}) => {
  return ApiRequest('/hospital/page-list', params, true, false, 'get')
}
export const deleteHospitalApi = (params = {}) => {
  return ApiRequest('/hospital/del', params, true, false, 'delete')
}
export const getUserRoleApi = (params = {}) => {
  return ApiRequest('/user/role-list', params, true, false, 'get')
}
export const getFactoryListApi = (params = {}) => {
  return ApiRequest('/factory/list', params, true, false, 'get')
}
export const createHospitalApi = (params = {}) => {
  return ApiRequest('/hospital/create', params, true, false, 'post')
}
export const updateHospitalApi = (params = {}) => {
  return ApiRequest('/hospital/update', params, true, false, 'put')
}
export const getFactoryPageListApi = (params = {}) => {
  return ApiRequest('/factory/page-list', params, true, false, 'get')
}
export const createFactoryApi = (params = {}) => {
  return ApiRequest('/factory/create', params, true, false, 'post')
}
export const updateFactoryApi = (params = {}) => {
  return ApiRequest('/factory/update', params, true, false, 'put')
}
export const deleteFactoryApi = (params = {}) => {
  return ApiRequest('/factory/delete', params, true, false, 'delete')
}
export const geteDepartmentPageListApi = (params = {}) => {
  return ApiRequest('/department/page-list', params, true, false, 'get')
}
export const deleteDepartmentApi = (params = {}) => {
  return ApiRequest('/department/delete', params, true, false, 'delete')
}
export const geteHospitalListApi = (params = {}) => {
  return ApiRequest('/hospital/list', params, true, false, 'get')
}
export const createDepartmentApi = (params = {}) => {
  return ApiRequest('/department/create', params, true, false, 'post')
}
export const updateDepartmentApi = (params = {}) => {
  return ApiRequest('/department/update', params, true, false, 'put')
}
export const getProductClassifyApi = (params = {}) => {
  return ApiRequest('/product/classify-tree', params, true, false, 'get')
}
export const createProductClassifyApi = (params = {}) => {
  return ApiRequest('/product/classify', params, true, false, 'post')
}
export const delProductClassifyApi = (params = {}) => {
  return ApiRequest('/product/classify', params, true, false, 'delete')
}
export const updateProductClassifyApi = (params = {}) => {
  return ApiRequest('/product/classify', params, true, false, 'put')
}
export const getProductPageListApi = (params = {}) => {
  return ApiRequest('/product/page-list', params, true, false, 'get')
}
export const delProductApi = (params = {}) => {
  return ApiRequest('/product', params, true, false, 'delete')
}
export const updateProductApi = (params = {}) => {
  return ApiRequest('/product', params, true, false, 'put')
}
export const createProductApi = (params = {}) => {
  return ApiRequest('/product', params, true, false, 'post')
}
export const getDepartmentProductPageListApi = (params = {}) => {
  return ApiRequest('/department/product-page-list', params, true, false, 'get')
}
export const getDepartmentDemandPageListApi = (params = {}) => {
  return ApiRequest('/department/demand-page-list', params, true, false, 'get')
}
export const delDepartmentProductApi = (params = {}) => {
  return ApiRequest('/department/product', params, true, false, 'delete')
}
export const delDepartmentDemandApi = (params = {}) => {
  return ApiRequest('/department/demand', params, true, false, 'delete')
}
export const createDepartmentProductApi = (params = {}) => {
  return ApiRequest('/department/product', params, true, false, 'post')
}
export const createDepartmentDemandApi = (params = {}) => {
  return ApiRequest('/department/demand', params, true, false, 'post')
}
export const getNoticePageListApi = (params = {}) => {
  return ApiRequest('/notice/page-list', params, true, false, 'get')
}
export const createNoticeApi = (params = {}) => {
  return ApiRequest('/notice', params, true, false, 'post')
}
export const delNoticeApi = (params = {}) => {
  return ApiRequest('/notice', params, true, false, 'delete')
}
export const updateNoticeApi = (params = {}) => {
  return ApiRequest('/notice', params, true, false, 'put')
}
export const getStatisticsCommonApi = (params = {}) => {
  return ApiRequest('/statistics/common', params, true, false, 'get')
}
export const getDepartmentListApi = (params = {}) => {
  return ApiRequest('/department/list', params, true, false, 'get')
}
export const getDocumentReceiptApi = (params = {}) => {
  return ApiRequest('/document/receipt', params, true, false, 'get')
}
export const getDocumentReceiptDetailApi = (params = {}) => {
  return ApiRequest('/document/receipt-detail', params, true, false, 'get')
}
export const getDocumentDemandApi = (params = {}) => {
  return ApiRequest('/document/demand', params, true, false, 'get')
}
export const exportDocumentReceiptApi = (params = {}) => {
  return ApiRequest('/document/receipt-export', params, true, false, 'get', '', 'blob')
}
export const exportDocumentDemandApi = (params = {}) => {
  return ApiRequest('/document/demand-export', params, true, false, 'get', '', 'blob')
}
export const getDocumentLackApi = (params = {}) => {
  return ApiRequest('/document/lack', params, true, false, 'get')
}
export const exportDocumentLackApi = (params = {}) => {
  return ApiRequest('/document/lack-export', params, true, false, 'get', '', 'blob')
}
export const getDocumentDeliveryApi = (params = {}) => {
  return ApiRequest('/document/delivery', params, true, false, 'get')
}
export const exportDocumentDeliveryApi = (params = {}) => {
  return ApiRequest('/document/delivery-export', params, true, false, 'get', '', 'blob')
}
export const getDocumentSupplyApi = (params = {}) => {
  return ApiRequest('/document/supply', params, true, false, 'get')
}
export const exportDocumentSupplyApi = (params = {}) => {
  return ApiRequest('/document/supply-export', params, true, false, 'get', '', 'blob')
}
export const createDocumentDeliveryApi = (params = {}) => {
  return ApiRequest('/document/delivery', params, true, false, 'post')
}
export const createDocumentSupplyApi = (params = {}) => {
  return ApiRequest('/document/supply', params, true, false, 'post')
}
export const getDocumentLiquidatePreviewApi = (params = {}) => {
  return ApiRequest('/document/liquidate-preview', params, true, false, 'get')
}
export const createDocumentLiquidateSupplyApi = (params = {}) => {
  return ApiRequest('/document/liquidate', params, true, false, 'post')
}
export const getDocumentLiquidateApi = (params = {}) => {
  return ApiRequest('/document/liquidate', params, true, false, 'get')
}
export const exportDocumentLiquidateApi = (params = {}) => {
  return ApiRequest('/document/liquidate-export', params, true, false, 'get', '', 'blob')
}
export const getUserWxBindApi = (params = {}) => {
  return ApiRequest('/user/wx-bind', params, true, false, 'get')
}
export const getStatisticsMonthPageApi = (params = {}) => {
  return ApiRequest('/statistics/month-page', params, true, false, 'get')
}
export const rejectDocumentDemandApi = (params = {}) => {
  return ApiRequest('/document/demand-re', params, true, false, 'post')
}
export const createBlockApi = (params = {}) => {
  return ApiRequest('/pick/block', params, true, false, 'put')
}
export const getBlockListApi = (params = {}) => {
  return ApiRequest('/pick/block', params, true, false, 'get')
}
export const deleteBlockApi = (params = {}) => {
  return ApiRequest('/pick/block', params, true, false, 'delete')
}
export const updateBlockApi = (params = {}) => {
  return ApiRequest('/pick/block', params, true, false, 'post')
}
export const getTaskListApi = (params = {}) => {
  return ApiRequest('/pick/task', params, true, false, 'get')
}
export const createTaskApi = (params = {}) => {
  return ApiRequest('/pick/task', params, true, false, 'put')
}
export const deleteTaskApi = (params = {}) => {
  return ApiRequest('/pick/task', params, true, false, 'delete')
}
